/* Global styles */

@font-face {
  font-family: 'Poppins-Regular';
  src: local('Poppins-Regular'), url('./fonts/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Bold';
  src: local('Poppins-Bold'), url('./fonts/Poppins-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Medium';
  src: local('Poppins-Medium'), url('./fonts/Poppins-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-SemiBold';
  src: local('Poppins-SemiBold'), url('./fonts/Poppins-SemiBold.ttf') format('truetype');
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Poppins-Regular', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #27292d;
}

svg {
  display: block;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html {
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
}

html::-webkit-scrollbar {
  width: 7px;
}

html::-webkit-scrollbar-thumb {
  background: #d5dee3;
  border-radius: 10px;
}

html::-webkit-scrollbar-thumb:hover {
  background: #555;
}

html,
body,
#root {
  height: 100%;
}

/* Hide default captcha badge */
.grecaptcha-badge {
  visibility: hidden;
}

/* debug css */
/* * {
  outline: 1px solid limegreen !important;
} */
