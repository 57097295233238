.loader3 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle1 {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 0 5px;
  background-color: #bec2cc;
  animation: circle1 1s ease-in-out infinite;
}

.circle1:nth-child(2) {
  animation-delay: 0.2s;
}

.circle1:nth-child(3) {
  animation-delay: 0.4s;
}

.circle1:nth-child(4) {
  animation-delay: 0.6s;
}

.circle1:nth-child(5) {
  animation-delay: 0.8s;
}

@keyframes circle1 {
  0% {
    opacity: 1;
  }

  50% {
    background-color: #1f2023;
  }

  100% {
    opacity: 1;
  }
}
